import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import ExchangeModal from "./ExchangeModal";
import { toastAlert } from "../../lib/toastAlert";
import UnStakeModal from "./UnstakeModal";
import { getAllStakeOrder } from "../../api/staking";
import { array } from "prop-types";

const initialFormValue = {
  isModalOpen: false,
  record: {},
};

const WalletDetails = () => {

  //redux
  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceConversion);
  const { isStake, isTrade, isExchange, adminComment } = useSelector((state) => state.account);

  const history = useNavigate()

  //state
  const [showBalance, setshowBalance] = useState(false);
  const [totalUSD, setTotalUSD] = useState(0);
  const [Wallet, setWallet] = useState([]);
  const [check, setCheck] = useState(false);
  const [filter, setFilter] = useState(true)
  const [exchangeData, setExhangeData] = useState(initialFormValue);
  const [loader, setLoading] = useState(true);
  const [unstakeData, setUnStakeData] = useState(initialFormValue);
  const [allStake, setAllStake] = useState([])
  const [totalPenalty, setPenalty] = useState(0)

  //function
  const handleSearch = (e) => {
    let { name, value } = e.target;
    function getSearch(item) {
      return (
        item.coin.toLowerCase() == value?.toLowerCase() ||
        item.stakeBal == value ||
        item.spotInOrder == value ||
        item.USDValue == value
      );
    }
    let filterDoc = Wallet.filter(getSearch);
    if (!isEmpty(filterDoc)) {
      setWallet(filterDoc);
      setFilter(true)
    } else {
      setFilter(false)
    }

    if (isEmpty(value)) {
      handleAsset();
      setFilter(true)
    }
  };

  const handleAsset = () => {
    try {
      let totalAmount = 0;
      let tempArr = [...walletData];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );

          // let stakeAmount = allStake?.length > 0 && allStake.find((el) => el.currencyId == item._id)
          let totalStakeAmount = allStake
            ?.filter((el) => el.currencyId == item._id)
            .reduce((acc, curr) => acc + curr.amount, 0);

          // console.log(totalStakeAmount, "-------totalStakeAmount")

          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          // console.log(pairIndex, "---------pairIndex")
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            let Balance = tempArr[pairIndex].stakeBal - tempArr[pairIndex].lockedBal
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                image: item.image,
                decimals: item.decimals,
                status: item.status,
                stakeAmount: totalStakeAmount,
                USDValue: !isEmpty(PriceCnv?.convertPrice) ? parseFloat(Balance * PriceCnv.convertPrice) : Balance,
              },
            };
            totalAmount += tempArr[pairIndex].USDValue;
          }
        });
      setTotalUSD(totalAmount);
      setWallet(tempArr);
      setLoading(false)
    } catch (err) {
      console.log("err:------ ", err);
    }
  };


  const handleCloseModal = () => {
    setExhangeData({ isModalOpen: false, record: {} });
  };


  const handleStake = () => {
    if (isStake) {
      history("/staking")
    } else {
      toastAlert("error", "Stake Access Blocked", "login");
    }

  }

  const handleExchange = (item) => {
    if (isExchange) {
      setExhangeData({
        isModalOpen: true,
        record: item?.item,
      });
    } else {
      toastAlert("error", "Exchange Access Blocked", "login");
    }
  }


  const handleUnstake = (item) => {

    if (item?.item?.stakeAmount <= 0) {
      toastAlert("error", "There is no staked amount", "login");
    } else {
      setUnStakeData({
        isModalOpen: true,
        record: item?.item,
      });
    }
  }

  const UnstakeClose = () => {
    setUnStakeData({ isModalOpen: false, record: {} });
  };

  const fetchStakeOrder = async () => {
    try {
      let array = []
      const { status, result, loading, message } = await getAllStakeOrder()
      if (status == 'success') {
        result && result.length > 0 && result.map((item) => {
          if (item.type == 'fixed')
            array.push(item)
        })
        setAllStake(array)
      } else {
        setAllStake([])
      }
    } catch (err) {
      console.log(err, 'error')
    }
  }





  useEffect(() => {
    if (!isEmpty(walletData) && !isEmpty(currency) && !isEmpty(priceConversion)) {
      handleAsset();
    }
    fetchStakeOrder()
  }, [walletData, currency, priceConversion]);


  // console.log(Wallet, 'WalletWalletWallet')
  return (
    <>
      <ExchangeModal
        isShow={exchangeData.isModalOpen}
        record={exchangeData.record}
        onHide={handleCloseModal} />

      <UnStakeModal isShow={unstakeData.isModalOpen} record={unstakeData.record} onHide={UnstakeClose} />

      {!isEmpty(adminComment) && <div className="primary_btn" style={{ "width": "50%" }} ><p className="text-small" >{adminComment}</p></div>}
      <br />
      <div className="staking_top_flex">
        <div className="wallet_bal">
          <h4>
            Total Balance :{" "}
            <span>$ {showBalance ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(totalUSD ? totalUSD : 0, 2)} /> : "********"}</span>
          </h4>
          <i
            onClick={() => setshowBalance(showBalance ? false : true)}
            className={showBalance ? "bi bi-eye" : "bi bi-eye-slash"}
          ></i>
        </div>
        <div className="wallet_page_right_flex">
          <div className="search_grp">
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearch}
              className="form-control"
            />
            <img
              src={require("../../assets/images/search_icon.png")}
              alt="Search"
              className="img-fluid search_icon"
            />
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              onClick={() => setCheck(check ? false : true)}
            />
            <label class="form-check-label" for="inlineCheckbox1">
              Hide Zero Balances
            </label>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table wallet_table">
          <thead>
            <tr>
              <th>Asset</th>
              <th>Balance</th>
              {/* <th>Locked</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Wallet?.length > 0 &&
              Wallet.map((item, index) => {
                let Balance = item?.stakeBal - item?.lockedBal;
                if (filter && item?.status == "active") {
                  if (check) {
                    if (item?.stakeBal > 0) {
                      return (
                        <tr>
                          <td>
                            <div className="table_asset">
                              <img
                                src={item.image}
                                alt="Crypto"
                                className="img-fluid"
                                loading="lazy"
                              />
                              <span>{item.coin}</span>
                            </div>
                          </td>
                          <td>
                            <p className="mb-0">
                              <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Balance, item?.decimals)} />{" "}
                              &nbsp; {item?.coin}
                            </p>
                            <span>≈ $ {item.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.USDValue, item?.decimals)} /> : 0}
                            </span>
                          </td>
                          {/* <td>0 BTC</td> */}
                          <td>
                            <div className="button_grp mt-0">
                              <Link to={`/deposit/${item?._id}`} className="primary_btn">
                                Deposit
                              </Link>
                              <Link
                                to={`/withdraw/${item?._id}`}
                                className="primary_btn"
                              >
                                Withdraw
                              </Link>

                              <button to="#" className="primary_btn" onClick={() => {
                                handleUnstake({
                                  item
                                });
                              }}
                              >Unstake</button>
                              {/* <button to="#" onClick={() => {
                                handleExchange({
                                  item
                                });
                              }} className="primary_btn" >Exchange</button> */}

                              <button onClick={handleStake} className="primary_btn" >Staked &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.stakeAmount}&nbsp;&nbsp;{item?.coin}</button>

                            </div>
                          </td>
                        </tr>
                      );
                    }
                  } else {
                    if (filter) {
                      return (
                        <tr>
                          <td>
                            <div className="table_asset">
                              <img
                                src={item?.image}
                                alt="Crypto"
                                className="img-fluid"
                              />
                              <span>{item?.coin}</span>
                            </div>
                          </td>
                          <td>
                            <p className="mb-0">
                              <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Balance, item?.decimals)} />
                              &nbsp;{item?.coin}
                            </p>
                            <span>≈ $ {item.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.USDValue, item?.decimals)} /> : 0} </span>
                          </td>
                          <td>
                            <div className="button_grp mt-0">
                              <Link to={`/deposit/${item?._id}`} className="primary_btn">
                                Deposit
                              </Link>
                              <Link
                                to={`/withdraw/${item?._id}`}
                                className="primary_btn"
                              >
                                Withdraw
                              </Link>
                              <button to="#" className="primary_btn" onClick={() => {
                                handleUnstake({
                                  item
                                });
                              }}
                              >Unstake</button>
                              {/* <button to="#" onClick={() => {
                                handleExchange({
                                  item
                                });
                              }} className="primary_btn" >Exchange</button> */}

                              <button onClick={handleStake} className="primary_btn" >Staked  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.stakeAmount}&nbsp;&nbsp;{item?.coin}</button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  }
                }
              })}
            {
              !loader && Wallet && Wallet.length <= 0 || !filter && <span style={{ position: 'center' }} >There are no records to display</span>
            }
            {loader && (
              <span style={{ textAlign: "center", display: "inline-block" }}>
                Loading...
              </span>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WalletDetails;
